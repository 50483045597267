import React, { useState } from "react";
import cx from "classnames";
import { Modal, Backdrop, Slide } from "@material-ui/core/";
import { Image } from "src/components/image";

import { ReactComponent as CloseX } from "src/images/svg/x.svg";
import { ReactComponent as DollarSign } from "src/images/svg/dollar-sign.svg";

import * as styles from "./unit-modal.module.css";

interface UnitModalProps {
  activeUnit: {
    unit: {
      Units: {
        Unit: {
          FloorplanName: string;
          MarketingName: string;
          UnitBedrooms: number;
          UnitBathrooms: number;
          UnitRent: number;
          UnitType: string;
        };
      };
    };
    applicationUrl: string;
    leaseTerm: number;
    freeMonths: number;
    finePrint?: string;
    availableMessage: string;
    unitImageURLs: string;
    apartmentName: any;
    bath: number;
    formattedRent: string;
  };

  open: boolean;
  toggle: () => void;
}

export const UnitModal = ({
  activeUnit,

  open,
  toggle,
}: UnitModalProps) => {
  const {
    unit,
    applicationUrl,
    leaseTerm,
    freeMonths,
    finePrint,
    availableMessage,
    unitImageURLs,
    apartmentName,
    availableDate,
    beds,
    bath,
    unitCategorySlug,
    formattedRent,
    fpVideoEmbedCode,
    matterportLinkout,
  } = activeUnit;
  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={toggle}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: {
          enter: 600,
          exit: 300,
        },
      }}
    >
      <Slide
        direction="left"
        in={open}
        timeout={{
          enter: 600,
          exit: 300,
        }}
      >
        <div className={styles.unitModal}>
          <button
            className={cx("corner-x", styles.closeButton)}
            onClick={toggle}
          >
            <CloseX />
          </button>
          {
            <div className={styles.inner}>
              <div className={styles.sidebar}>
                <h2 className={cx("hide-small hide-medium", styles.unitType)}>
                  <span className="serif serif serif--155">
                    {apartmentName}
                  </span>
                </h2>
                <p
                  className={cx(
                    "sans sans--caps-11 hide-small hide-medium",
                    styles.unitDescription
                  )}
                >
                  <span className="letter sans sans--caps-11">
                    {unitCategorySlug}
                  </span>
                  <span className="number sans sans--40">
                    {parseFloat(bath)}
                  </span>
                  <span className="letter sans sans--caps-11">bath</span>
                </p>
                <div className={styles.unitDetails}>
                  <div className={cx(styles.unitNumberAvailability)}>
                    <p className="sans sans--caps-11">
                      {/* Unit {ApartmentName} */}
                      <span
                        className={cx("hide-min-large", styles.mobileUnitRent)}
                      >
                        ${formattedRent}
                        <span
                          className={cx("sans sans--body-16", styles.asterisk)}
                        >
                          *
                        </span>
                      </span>
                    </p>
                    {unitCategorySlug !== "co-living" ? (
                      <p className="sans sans--caps-11 medium">
                        Available {availableDate}
                      </p>
                    ) : (
                      // <p className="sans sans--caps-11 medium">
                      //   Available{" "}
                      //   {new Date().toLocaleDateString("en-US", {
                      //     month: "numeric",
                      //     day: "numeric",
                      //     year: "numeric",
                      //   })}
                      // </p>
                      <p className="sans sans--caps-11 medium">
                        Available {availableDate}
                      </p>
                    )}
                  </div>
                  <p className={cx("serif serif--36", styles.unitRent)}>
                    <DollarSign />
                    {formatter.format(`${formattedRent}`)}

                    {leaseTerm > 12 && (
                      <span
                        className={cx("sans sans--body-16", styles.asterisk)}
                      >
                        *
                      </span>
                    )}
                  </p>
                </div>

                <div className={cx("", styles.applyNow)}>
                  <a
                    className={cx("serif serif--60 lower", styles.applyNowLink)}
                    // href={applicationUrl}
                    href={applicationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
                {matterportLinkout && (
                  <div className={cx("", styles.applyNow)}>
                    <a
                      className={cx(
                        "serif serif--60 lower",
                        styles.applyNowLink
                      )}
                      // href={applicationUrl}
                      href={matterportLinkout}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Virtual Tour
                    </a>
                  </div>
                )}
              </div>
              <div className={styles.main}>
                {/* <a
                  href={`${unitImageURLs[0]}`}
                  className={cx(
                    "sans sans--caps-11 medium",
                    styles.downloadLink
                  )}
                  target="_blank"
                >
                  Download
                </a> */}
                <Image className={styles.floorplanImage} src={unitImageURLs} />
                {leaseTerm > 12 && (
                  <p
                    className={cx(
                      "sans sans--body-14 sans--light",
                      styles.note
                    )}
                  >
                    *Pricing reflects {freeMonths} month
                    {freeMonths > 1 ? "s" : ""} free on a {leaseTerm} month
                    lease. Contact leasing agent for details.
                  </p>
                )}
              </div>
            </div>
          }
        </div>
      </Slide>
    </Modal>
  );
};
