import React, { useState } from "react";
import cx from "classnames";
import { UnitModal } from "src/components/availability/unit-modal";

import { ReactComponent as DollarSign } from "src/images/svg/dollar-sign.svg";

import styles from "./unit-grid.module.css";

export interface UnitCardProps {
  unit: {
    Availability: {
      UnitAvailabilityURL: string;
      VacateDate: any;
    };
  };

  unitId: number;
  floorplanId: number;
  propertyId: string;
  formattedRent: string;
  availableDate: string;
  beds: string;
  bath: string;
  unitImageURLs: string;
  applyOnlineURL: string;
  fpVideoEmbedCode: string;
  apartmentName: string;
  unitCategorySlug: string;
  leaseTerm: number;
  handleClick: (
    unit: {},
    formattedRent: string,
    applicationUrl: string,
    unitImageURLs: string,
    availableDate: string,
    beds: string,
    bath: string,
    apartmentName: string,
    unitCategorySlug: string
  ) => void;
}

const UnitCard = ({
  unit,
  unitId,
  floorplanId,
  propertyId,
  availableDate,
  beds,
  bath,
  unitImageURLs,
  applyOnlineURL,
  fpVideoEmbedCode,
  unitCategorySlug,
  formattedRent,
  apartmentName,
  leaseTerm,
  handleClick,
}: UnitCardProps) => {
  const applicationUrl = `${applyOnlineURL}`;
  const matterportLinkout = fpVideoEmbedCode
    ? fpVideoEmbedCode.match(/src="([^"]+)"/)?.[1] || ""
    : "";

  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <button
      className={cx(styles.unitCard)}
      onClick={() =>
        handleClick(
          applicationUrl,
          matterportLinkout,
          unitImageURLs,
          apartmentName,
          availableDate,
          beds,
          bath,
          unitCategorySlug,
          formattedRent
        )
      }
    >
      <div className={styles.unitCardInner}>
        <h2 className={cx(styles.unitType)}>
          <span className="serif serif serif--155">{apartmentName}</span>
        </h2>
        <div className={cx(styles.unitDetails)}>
          <div className={styles.unitNumberAvailability}>
            {/* <p className="sans sans--caps-11">Unit sd</p> */}
            {unitCategorySlug !== "co-living" ? (
              <p className="sans sans--caps-11 medium">
                Available {availableDate}
              </p>
            ) : (
              // <p className="sans sans--caps-11 medium">
              //   Available{" "}
              //   {new Date().toLocaleDateString("en-US", {
              //     month: "numeric",
              //     day: "numeric",
              //     year: "numeric",
              //   })}
              // </p>
              <p className="sans sans--caps-11 medium">
                Available {availableDate}
              </p>
            )}
          </div>
          <p className={cx("serif serif--36", styles.unitRent)}>
            <DollarSign />
            {formatter.format(`${formattedRent}`)}
          </p>
        </div>
        <p className={cx("sans sans--caps-11", styles.unitDescription)}>
          <span className="number sans sans--40">{beds === 0 ? "" : beds}</span>
          <span className="letter sans sans--caps-11">
            {beds === 0 ? "Studio" : "BEDROOM"}
          </span>
          <span className="number sans sans--40">{parseFloat(bath)}</span>
          <span className="letter sans sans--caps-11">BATH</span>
        </p>
      </div>
    </button>
  );
};

interface RentDiscount {
  unitType?: string;
  leaseTerm?: number;
  freeMonths?: number;
  finePrint?: string;
}

export interface UnitsProps {
  propertyId: string;
  units: any;
  rentDiscounts: {
    generalRentDiscount: RentDiscount;
    phase1_1RentDiscount: RentDiscount[];
    phase1_2RentDiscount: RentDiscount[];
  };
}

export const UnitGrid = ({
  propertyId,
  units,
  res,
  unitCategorySlug,
}: UnitsProps) => {
  const [unitModalOpen, setUnitModalOpen] = useState(false);
  const [activeUnit, setActiveUnit] = useState<any | undefined>(undefined);

  const toggleUnitModal = () => {
    setUnitModalOpen(!unitModalOpen);
  };

  const handleClick = (
    applicationUrl: string,
    matterportLinkout: string,
    unitImageURLs: any,
    apartmentName: any,
    availableDate: any,
    beds: any,
    bath: any,
    unitCategorySlug: any,
    formattedRent: any
  ) => {
    setActiveUnit({
      formattedRent,
      applicationUrl,
      matterportLinkout,
      unitImageURLs,
      apartmentName,
      availableDate,
      beds,
      bath,
      unitCategorySlug,
    });
    setUnitModalOpen(true);
  };
  console.info("activeUnit", activeUnit);

  return (
    <>
      {/* <div style={{ margin: "0 auto", textAlign: "center" }}>
        *Prices and availability are subject to change.
      </div> */}
      <div className={styles.unitGrid}>
        {res &&
          res.map((node: any) => {
            return (
              <UnitCard
                formattedRent={node.minimumRent}
                apartmentName={node.apartmentName}
                availableDate={node.availableDate}
                beds={node.beds}
                bath={node.baths}
                unitImageURLs={node.unitImageURLs}
                applyOnlineURL={node.applyOnlineURL}
                unitCategorySlug={unitCategorySlug}
                fpVideoEmbedCode={node.fpVideoEmbedCode}
                handleClick={handleClick}
              />
            );
          })}
        {/* <div style={{ margin: "48px" }}>
          *Prices and availability are subject to change.
        </div> */}
      </div>

      {activeUnit && (
        <UnitModal
          activeUnit={activeUnit}
          open={unitModalOpen}
          toggle={toggleUnitModal}
        />
      )}
    </>
  );
};
